
















import { AsyncComponent, VueConstructor } from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig } from '../../../../contexts'
import { FiltersHandlerMixin } from '../../../../support/mixins/FiltersHandler.mixin.vue'
import { logger } from '../../../../support'
import StructureConfigurable from '../../../../support/mixins/StructureConfigurable.mixin.vue'

import {
  FilterLayout,
  FILTERS_COMPONENT_TYPE_KEY,
  FiltersComponentConfig, filtersComponentConfigMap
} from '../Filters/Filters.config'

import { FiltersWrapperProps } from '../FiltersWrapper/FiltersWrapper.contracts'
import { listDisplayControlsComponentsRegistry } from './ListDisplayControls.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ListDisplayControls>({
  name: 'ListDisplayControls',
  created () {
    this.config = this.getComponentConfig(FILTERS_COMPONENT_TYPE_KEY, filtersComponentConfigMap)
  }
})
export class ListDisplayControls extends Mixins(FiltersHandlerMixin, StructureConfigurable) {
  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: false, type: Number })
  public total?: FiltersWrapperProps['total'] | null

  protected config!: FiltersComponentConfig

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  public get icons (): string {
    return this.getConfigProperty<string>('icons')
  }

  public get withModeSwitch (): boolean {
    return this.getConfigProperty<boolean>('withModeSwitch')
  }

  public get withCount (): boolean {
    return this.getConfigProperty<boolean>('withCount')
  }

  public get withVisibilityToggle (): boolean {
    return this.getConfigProperty<boolean>('withVisibilityToggle')
  }

  public get pageComponentType (): VueConstructor | AsyncComponent {
    if (!(this.config.layout in listDisplayControlsComponentsRegistry)) {
      logger('[Filters.layout] has not been defined in App configuration! Default value might not be suitable for current project.', 'warn')
      return listDisplayControlsComponentsRegistry[FilterLayout.Popover]
    }

    return listDisplayControlsComponentsRegistry[this.config.layout]
  }

  public getLabel (paramConfig: FilterParamConfig) {
    if (!this.filters) {
      return ''
    }

    const value = this.filters[paramConfig.queryParam] as string

    return paramConfig.options[value] ?? ''
  }

  @Watch('filters')
  private update () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }

  public updateQuery (newValue: FiltersConfig) {
    this.$emit('setQuery', this.getQueryParams(newValue, this.query), newValue)
  }
}

export default ListDisplayControls
